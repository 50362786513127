import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import JobsListingSaved from "../components/job-listing-saved"
import JobListingComponent from "../components/job-listing"
import HeroMazeDefault from "../components/hero-default"
import MobileFilter from "../components/mobile-filter"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"

export interface IJobDetail {
  id: string
  text: string
  applyUrl: string
}

export interface IJobListingProps {
  allLever: {
    nodes: Array<IJobDetail>
  }
}

interface ICareersHomePageContext {
  id: string
  slug: string
  locale: string
}

const JobListing: React.FC<PageProps<IJobListingProps, ICareersHomePageContext>> = ({ pageContext, path, data }) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: "Job listing",
  }

  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      {/* Layout contains menus */}
      <Layout overflowEnable exploreDisable seoData={seoData}>
        <HeroMazeDefault
          subheading="Latest roles..."
          heading="Find your path"
          content="Every journey starts somewhere. And your Guidewire journey starts right here, as you navigate our current open roles."
          className="jobs-hero"
        />
        <section className="jobs-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-7 main-col">
                <JobListingComponent leverJobs={data.allLever.nodes} showPagination jobListingPage className='show-featured-title'/>
              </div>
              <section className="col-md-5 offset-md-1 col-lg-4 sidebar">
                <MobileFilter />
              </section>
            </div>
          </div>
        </section>
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default JobListing

export const query = graphql`
  query {
    allLever(sort: { fields: createdAt, order: DESC }) {
      nodes {
        ...JobPosting
      }
    }
  }
`
